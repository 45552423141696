import {CSSStyles} from '../../types/CSSStyles';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly src: string;
  readonly height?: number | string;
  readonly srcSet?: string;
  readonly sx?: CSSStyles;
  readonly width?: number | string;
}

export default function Image(props: Props): JSX.Element {
  const {height, src, srcSet, sx, width} = props;

  return (
    <div
      css={[
        css({
          height,
          pointerEvents: 'none',
          userSelect: 'none',
          width,
        }),
        sx,
      ]}
    >
      <img
        height={height}
        src={src}
        srcSet={srcSet}
        width={width}
      />
    </div>
  );
}
