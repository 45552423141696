import {CSSStyle} from '../../../types/CSSStyles';
import {linkToApp} from '../../../url/linkToApp';
import {linkToMarketing} from '../../../url/linkToMarketing';
import Link from '../../design_system/Link/Link';
import Image from '../../Image/Image';

import {css} from '@emotion/react';
import React from 'react';

export type BrandPlacement = 'marketing' | 'app';
export interface Props {
  readonly placement?: BrandPlacement;
  readonly rootStyle?: CSSStyle;
}

export default function Brand(props: Props): JSX.Element {
  const {placement = 'marketing', rootStyle} = props;
  let path;
  switch (placement) {
    case 'marketing':
      path = linkToMarketing('/');
      break;
    case 'app':
      path = linkToApp('/');
  }
  return (
    <Link
      sx={[
        css({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          textDecoration: 'none',
        }),
        rootStyle,
      ]}
      to={path}
    >
      <Image
        height="100%"
        src="/static/images/brand-logo.svg"
      />
    </Link>
  );
}
