import FlatButton from './design_system/Button/FlatButton';
import ChevronUp from './design_system/icon/ChevronUp';
import Globe from './design_system/icon/Globe';
import Flex from './layout/flex/Flex';
import {SUPPORTED_LANGUAGES, SupportedLanguageKey} from '../../server/lib/i18n';
import brown from './design_system/theme/colors/brown';
import pink from './design_system/theme/colors/pink';
import white from './design_system/theme/colors/white';

import {css} from '@emotion/react';
import {AnimatePresence, motion} from 'motion/react';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

export default function LanguageMenu(): JSX.Element {
  const {i18n} = useTranslation();
  const [isOptionsHidden, setIsOptionsHidden] = useState(true);
  const languageMenuRef = useRef<HTMLDivElement | null>(null);

  const handleButtonClick = async (language: string) => {
    setIsOptionsHidden(true);
    await i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (!languageMenuRef.current) return;
    const currentButtonRef = languageMenuRef.current;

    const setIsHidden = () => {
      setIsOptionsHidden(true);
    };

    const setIsNotHidden = () => {
      setIsOptionsHidden(false);
    };

    currentButtonRef.addEventListener('mouseenter', setIsNotHidden);
    currentButtonRef.addEventListener('mouseleave', setIsHidden);

    return () => {
      currentButtonRef.removeEventListener('mouseenter', setIsNotHidden);
      currentButtonRef.removeEventListener('mouseleave', setIsHidden);
    };
  }, []);

  return (
    <div
      css={css({
        height: '34px',
        width: '132px',
        maxWidth: '132px',
        ':hover': {
          '#chevron-up': {rotate: '180deg'},
        },
        padding: '2px',
        position: 'relative',
        cursor: 'pointer',
      })}
      ref={languageMenuRef}
    >
      <FlatButton
        sx={css({
          gap: '10px',
          width: '100%',
          height: '100%',
          color: 'black',
        })}
      >
        <Flex
          direction="row"
          sx={css({
            gap: '10px',
            width: '100%',
            height: '100%',
          })}
        >
          <Globe />
          <span
            css={css({
              display: 'flex',
              justifyContent: 'center',
              flexGrow: 1,
              fontSize: '13px',
              fontWeight: '700',
            })}
          >
            {SUPPORTED_LANGUAGES[i18n.language as SupportedLanguageKey]}
          </span>
          <ChevronUp
            css={css({
              transition: 'rotate 0.3s ease',
            })}
            id="chevron-up"
          />
        </Flex>
      </FlatButton>
      <AnimatePresence>
        {!isOptionsHidden && (
          <motion.div
            animate={{opacity: 100}}
            css={css({
              display: 'flex',
              flexDirection: 'column',
              top: '100%',
              width: '100%',
              left: 0,
              position: 'absolute',
              background: white.solid,
              borderBottom: '1px solid black',
              borderBottomRightRadius: '6px',
              borderBottomLeftRadius: '6px',
              borderColor: brown.brown900,
            })}
            exit={{opacity: 0}}
            initial={{opacity: 0}}
          >
            {Object.keys(SUPPORTED_LANGUAGES).map((key, index) => {
              if (key === i18n.language) return;
              return (
                <FlatButton
                  key={index}
                  sx={css({
                    height: '34px',
                    fontSize: '13px',
                    fontWeight: '700',
                    transition: 'color 0.15s ease',
                    color: 'black',
                    ':hover': {
                      color: pink.pink800,
                    },
                  })}
                  onPress={() => {
                    void handleButtonClick(key);
                  }}
                >
                  <Flex
                    css={css({
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                    })}
                  >
                    {SUPPORTED_LANGUAGES[key as SupportedLanguageKey]}
                  </Flex>
                </FlatButton>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
