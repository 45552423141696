import PolicyLink from './PolicyLink';
import {linkToMarketing} from '../../../url/linkToMarketing';
import useAppTheme from '../../design_system/hooks/useAppTheme';
import useMediaQuery from '../../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../../design_system/hooks/useMediaQueryGetter';
import Separator from '../../design_system/icon/Separator';
import {ContentColor} from '../../design_system/theme/useContentPalette';
import Typography, {Size} from '../../design_system/typography/Typography';
import Flex from '../flex/Flex';

import {css} from '@emotion/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

export interface Props {}

export default function PolicyFooter(_: Props): JSX.Element {
  const {palettes, spacing} = useAppTheme();
  const {t} = useTranslation();
  const wrappedMediaQuery = useMediaQuery(MediaQuery.SmallAndMedium);

  return (
    <Flex
      sx={css({
        justifyContent: 'center',
        backgroundColor: palettes.background.neutralContrast.default,
        position: 'relative',
        zIndex: 100,
      })}
    >
      <div
        css={css({
          alignItems: 'center',
          display: 'grid',
          paddingTop: spacing.x16,
          paddingBottom: spacing.x16,
          gridTemplateColumns: 'repeat(7, auto)',
          columnGap: spacing.x20,
          rowGap: spacing.x8,

          [wrappedMediaQuery]: {
            gridTemplateColumns: 'none',
            gridTemplateRows: 'repeat(7, 1fr)',
          },
        })}
        key="grid-container"
      >
        <Flex
          key="column-1"
          sx={css({
            justifyContent: 'center',
          })}
        >
          <Typography
            color={ContentColor.PRIMARY_CONTRAST}
            size={Size.XS}
            variant="div"
            weight={700}
          >
            {t('footer.powered-by')}
          </Typography>
        </Flex>

        <Flex
          key="column-2"
          sx={css({
            justifyContent: 'center',

            [wrappedMediaQuery]: {
              gridRow: '2',
            },
          })}
        >
          <Separator />
        </Flex>

        <Flex
          key="column-3"
          sx={css({
            justifyContent: 'center',

            [wrappedMediaQuery]: {
              gridRow: '3',
            },
          })}
        >
          <PolicyLink to={linkToMarketing('/privacy-policy')}>
            {t('pages.privacy-policy.page-title')}
          </PolicyLink>
        </Flex>

        <Flex
          key="column-4"
          sx={css({
            justifyContent: 'center',

            [wrappedMediaQuery]: {
              gridRow: '4',
            },
          })}
        >
          <Separator />
        </Flex>

        <Flex
          key="column-5"
          sx={css({
            justifyContent: 'center',
            [wrappedMediaQuery]: {
              gridRow: '5',
            },
          })}
        >
          <PolicyLink to={linkToMarketing('/terms-of-use')}>
            {t('pages.terms-of-use.page-title')}
          </PolicyLink>
        </Flex>

        <Flex
          key="column-6"
          sx={css({
            justifyContent: 'center',
            [wrappedMediaQuery]: {
              gridRow: '6',
            },
          })}
        >
          <Separator />
        </Flex>

        <Flex
          key="column-7"
          sx={css({
            justifyContent: 'center',
            [wrappedMediaQuery]: {
              gridRow: '7',
            },
          })}
        >
          <Typography
            color={ContentColor.PRIMARY_CONTRAST}
            size={Size.XS}
            variant="div"
          >
            {t('common-strings.copyright', {brand: t('common.brand')})}
          </Typography>
        </Flex>
      </div>
    </Flex>
  );
}
