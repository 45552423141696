import Button, {Props as ButtonProps} from './Button';
import {AppIconProps} from '../icon/AppIconProps';

import React, {forwardRef} from 'react';

type OmitKeys = 'labelIsHidden';
export interface Props extends Omit<ButtonProps, OmitKeys> {
  readonly icon: React.ReactElement<AppIconProps>;
}

function IconButton(props: Props, ref: React.ForwardedRef<unknown>) {
  return (
    <Button
      {...props}
      labelIsHidden={true}
      ref={ref}
    />
  );
}

export default forwardRef(IconButton);