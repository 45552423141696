import {useCallback} from 'react';
import {NavigateOptions, useNavigate, useSearchParams} from 'react-router-dom';

const DEFAULT_NEXT_URL = '/';

export type NavigateFunction = (options: NavigateOptions) => void;

export const NEXT_URL_SEARCH_PARAM_KEY = 'next';

export default function useNavigateToNextUrl(
  defaultUrl?: string,
): NavigateFunction {
  const [searchParams] = useSearchParams();
  const nextUrl =
    searchParams.get(NEXT_URL_SEARCH_PARAM_KEY) ??
    defaultUrl ??
    DEFAULT_NEXT_URL;
  const navigate = useNavigate();

  return useCallback(
    (options: NavigateOptions) => {
      navigate(nextUrl, {
        replace: true,
        ...options,
      });
    },
    [navigate, nextUrl],
  );
}
