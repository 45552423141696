import {CSSStyles} from '../../../types/CSSStyles';
import TextLink from '../../design_system/Link/TextLink';
import {ContentColor} from '../../design_system/theme/useContentPalette';

import React from 'react';

export interface Props {
  readonly to: string;
  readonly children?: React.ReactNode;
  readonly style?: CSSStyles;
}

export default function PolicyLink(props: Props): JSX.Element {
  const {children, style, to} = props;

  return (
    <TextLink
      sx={style}
      textProps={{
        color: ContentColor.PRIMARY_CONTRAST,
      }}
      to={to}
    >
      {children}
    </TextLink>
  );
}
