import useAppTheme from '../hooks/useAppTheme';

import {css, keyframes} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly 'data-testid'?: string;
}

export default function HorizontalProgress(props: Props): JSX.Element {
  const {'data-testid': testID} = props;
  const {palettes} = useAppTheme();

  const movement = keyframes({
    '0%': {
      left: 0,
      transform: 'translateX(-100%)',
    },
    '100%': {
      left: '100%',
      transform: 'translateX(0%)',
    },
  });

  return (
    <div
      css={css({
        width: '100%',
        height: 3,
        display: 'inline-block',
        overflow: 'hidden',

        '&::after': {
          content: '""',
          width: '30%',
          height: 5,
          backgroundColor: palettes.background.neutral.default,
          position: 'absolute',
          top: 0,
          left: 0,
          boxSizing: 'border-box',
          animation: `${movement} 2s linear infinite`,
        },
      })}
      data-testid={testID}
    />
  );
}
