import {UserAvatarButton_UserFragment} from './__generated__/ViewerNavControls.graphql';
import {linkToApp} from '../../../url/linkToApp';
import UserAvatarLink from '../../design_system/Link/UserAvatarLink';

import React from 'react';

export interface Props {
  readonly user: UserAvatarButton_UserFragment;
}

export default function NavAvatarButton(props: Props): JSX.Element {
  const {user} = props;
  return (
    <UserAvatarLink
      showUserDetail={true}
      to={linkToApp('/')}
      user={user}
    />
  );
}
